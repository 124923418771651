import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import classnames from 'classnames';
import 'suneditor/dist/css/suneditor.min.css';
import suneditor from 'suneditor';
import plugins from 'suneditor/src/plugins';
import SweetAlert from 'react-bootstrap-sweetalert';

import { activateAuthLayout } from '../../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { getQuoteVariables } from '../../../../helpers/common';
import axios from 'axios';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const instance = axios.create();

// Note: orderBy is not working if QuoteId is null
// const DATA = gql`
//   query getData($ProductId: ID) {
//     Product(ProductId: $ProductId) {
//       ProductId
//       Name
//       ProductTemplates {
//         ProductTemplateId
//         ProductTemplateName
//         ProductTemplateContent
//         ProductTemplateTOCLine
//       }
//     }

//     Quote(Status: "sent", orderBy: [{ field: "QuoteId", order: DESC }]) {
//       QuoteId
//       HubSpotDealId
//       Description
//       CompanyName
//     }
//   }
// `;

const DATA = gql`
  query getData($ProductId: ID) {
    Product(ProductId: $ProductId) {
      ProductId
      Name
      ProductTemplates {
        ProductTemplateId
        ProductTemplateName
        ProductTemplateContent
        ProductTemplateTOCLine
      }
    }

    Quote(Status: "sent") {
      QuoteId
      HubSpotDealId
      Description
      CompanyName
    }
  }
`;

const DEFAULT_PRODUCT_TEMPLATE = gql`
  query getData($ProductTemplateId: ID) {
    ProductTemplate(ProductTemplateId: $ProductTemplateId) {
      ProductTemplateId
      ProductTemplateName
      ProductTemplateContent
      ProductTemplateTOCLine
    }
  }
`;

const CREATE_PRODUCT_TEMPLATE = gql`
  mutation createProductTemplate($ProductTemplateInput: ProductTemplateInput) {
    createProductTemplate(input: $ProductTemplateInput) {
      ProductTemplateId
      ProductTemplateName
      ProductTemplateTOCLine
      ProductTemplateContent
      ProductId
    }
  }
`;

const UPDATE_PRODUCT_TEMPLATE = gql`
  mutation updateProductTemplate($ProductTemplateInput: ProductTemplateInput) {
    updateProductTemplate(input: $ProductTemplateInput) {
      ProductTemplateId
      ProductTemplateName
      ProductTemplateTOCLine
      ProductTemplateContent
      ProductId
    }
  }
`;

const editorOptions = {
  height: 400,
  plugins: plugins,
  placeholder: '',
  buttonList: [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    '/', // Line break
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['table', 'link', 'image'],
    ['fullScreen', 'showBlocks', 'codeView'],
    ['preview'],
  ],
};

const quoteTableVariables = [
  {
    id: 'contract_period',
    name: 'Contract',
  },
  {
    id: 'setup',
    name: 'Setup Costs Table',
  },
  {
    id: 'setup_cost',
    name: 'Setup Cost Comparison Table',
  },
  {
    id: 'monthly',
    name: 'Monthly Costs Table',
  },
  {
    id: 'monthly_cost',
    name: 'Monthly Cost Comparison Table',
  },
  {
    id: 'initial_cost_total',
    name: 'Initial Cost Total',
  },
  {
    id: 'monthly_total',
    name: 'Monthly Total',
  },
  {
    id: 'hardware',
    name: 'Hardware Lists Table',
  },
  {
    id: 'usage_rates',
    name: 'Usage Rates Table',
  },
  {
    id: 'usage_rates_cost',
    name: 'Usage Rates Cost Comparison Table',
  },
];

const quoteInfoVariables = [
  {
    id: 'handsets',
    name: 'Handsets',
  },
  {
    id: 'accessories',
    name: 'Accessories',
  },
  {
    id: 'prepared_on',
    name: 'Prepared Date',
  },
  {
    id: 'valid_until',
    name: 'Valid Date',
  },
];

const ProductTemplates = (props) => {
  props.activateAuthLayout();

  // initialize state
  const [quoteVariables, setQuoteVariables] = useState(null);
  const [editorClicked, setEditorClicked] = useState(false);
  const [saveTemplateResult, setSaveTemplateResult] = useState(null);
  const [isCreateTemplateDone, setIsCreateTemplateDone] = useState(false);
  const [isUpdateTemplateDone, setIsUpdateTemplateDone] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [templateEditors, setTemplateEditors] = useState(null);
  const [productTemplates, setProductTemplates] = useState(null);
  const [modal, setModal] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    ProductTemplateName: '',
    ProductTemplateTOCLine: '',
    DefaultProductTemplate: false,
  });
  const [productDefaultTemplate, setProductDefaultTemplate] = useState(null);

  // initialize apollo graphql queries and mutations
  const location = useLocation();
  const [pid, setPid] = useState(null);

  useEffect(() => {
    if(location.pathname) {
        let tmp = location.pathname.slice(location.pathname.lastIndexOf("/") , location.pathname.length) ;
        // remove the first slash
        tmp = tmp.substring(1);
        // replace the --- character that we put on admin/products
        tmp = tmp.replace('---', '=');
        setPid(tmp);
    }
}, [location])

  const { data } = useQuery(DATA, {
    variables: { ProductId: pid ? pid : 0 },
  });

  const { data: defaultProductTemplate } = useQuery(DEFAULT_PRODUCT_TEMPLATE, {
    variables: {
      ProductTemplateId: process.env.REACT_APP_DEFAULT_PRODUCT_TEMPLATE,
    },
  });
  const [createProductTemplate, { data: createdProductTemplateData }] =
    useMutation(CREATE_PRODUCT_TEMPLATE);
  const [updateProductTemplate, { data: updatedProductTemplateData }] =
    useMutation(UPDATE_PRODUCT_TEMPLATE);
  const [isSystemTemplate, setIsSystemTemplate] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let mounted = true;
    if (data && data.Product) {
      if (data.Quote) {
        getQuoteVariables(data.Quote.QuoteId).then((qv) => {
          if (mounted && quoteVariables === null) {
            setQuoteVariables(qv);
          }
        });
      } else {
        setQuoteVariables(null);
      }

      if (productTemplates === null) {
        setProductTemplates(data.Product.ProductTemplates);
      }

      if (productTemplates && templateEditors === null) {
        const editors = data.Product.ProductTemplates.map((template, index) =>
          suneditor.create(`template-${index}`, editorOptions)
        );
        editors.forEach((editor, index) => {
          editor.setContents(
            data.Product.ProductTemplates[index].ProductTemplateContent
          );
        });
        setTemplateEditors(editors);
      }

      if (templateEditors !== null && templateEditors.length > 0) {
        templateEditors[0].core.context.element.wysiwyg.blur();
        templateEditors.forEach((template) => {
          template.onClick = () => {
            if (editorClicked === false) setEditorClicked(true);
          };
        });
      }

      if (
        updatedProductTemplateData &&
        saveTemplateResult === null &&
        isUpdateTemplateDone === false
      ) {
        setSaveTemplateResult({
          message: 'Successfully updated template',
          success: true,
        });
      }

      if (
        createdProductTemplateData &&
        saveTemplateResult === null &&
        isCreateTemplateDone === false
      ) {
        setSaveTemplateResult({
          message: 'Successfully created new template',
          success: true,
        });
        setNewTemplate({
          ProductTemplateName: '',
          ProductTemplateTOCLine: '',
        });
        addNewTemplateToState(createdProductTemplateData.createProductTemplate);
        renderNewTemplateEditor();
      }

      if (
        updatedProductTemplateData &&
        saveTemplateResult &&
        saveTemplateResult.isPreview
      ) {
        const templateId =
          updatedProductTemplateData.updateProductTemplate.ProductTemplateId;
        window.open('/api/template-preview/' + templateId);
        setSaveTemplateResult({
          ...saveTemplateResult,
          isPreview: false,
        });
      }
      setIsSystemTemplate(pid === 'system' ? true : false);
    }
    if (
      defaultProductTemplate &&
      defaultProductTemplate.ProductTemplate &&
      productDefaultTemplate === null
    ) {
      setProductDefaultTemplate({ ...defaultProductTemplate.ProductTemplate });
    }

    return () => (mounted = false);
  });

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setEditorClicked(false);
    }
  };

  const toggleAddNewTemplateModal = () => {
    setModal(!modal);
  };

  const addNewTemplate = (e) => {
    e.preventDefault();
    if (newTemplate.ProductTemplateName && newTemplate.ProductTemplateTOCLine) {
      setSaveTemplateResult(null);
      setIsCreateTemplateDone(false);
      setModal(false);

      let templateProduct = '<div>&nbsp;</div>';
      if (newTemplate.DefaultProductTemplate) {
        templateProduct = productDefaultTemplate.ProductTemplateContent;
      }

      createProductTemplate({
        variables: {
          ProductTemplateInput: {
            ProductId: data.Product.ProductId,
            ProductTemplateName: newTemplate.ProductTemplateName,
            ProductTemplateTOCLine: newTemplate.ProductTemplateTOCLine,
            ProductTemplateContent: templateProduct,
          },
        },
      });
    }
  };

  const addNewTemplateToState = (productTemplate) => {
    // if the created template is not yet added push it to the productTemplates state
    if (
      productTemplates.filter(
        (template) =>
          template.ProductTemplateId === productTemplate.ProductTemplateId
      ).length === 0
    ) {
      const newTemplateData = {
        ProductTemplateId: productTemplate.ProductTemplateId,
        ProductId: data.Product.ProductId,
        ProductTemplateName: productTemplate.ProductTemplateName,
        ProductTemplateTOCLine: productTemplate.ProductTemplateTOCLine,
        ProductTemplateContent: productTemplate.ProductTemplateContent,
      };
      const productTemplatesArr = Array.from(productTemplates);
      productTemplatesArr.push(newTemplateData);
      setProductTemplates(productTemplatesArr);
    }
  };

  const renderNewTemplateEditor = () => {
    if (productTemplates.length !== templateEditors.length) {
      const editors = Array.from(templateEditors);
      const newEditor = suneditor.create(
        `template-${productTemplates.length - 1}`,
        editorOptions
      );
      let content = productTemplates[productTemplates.length - 1];
      newEditor.setContents(content.ProductTemplateContent);
      editors.push(newEditor);
      setTemplateEditors(editors);
    }
  };

  const updateProductTemplateItem = (value, field, index) => {
    const productTemplate = Object.assign({}, productTemplates[index]);
    productTemplate[field] = value;
    const newProductTemplates = Array.from(productTemplates);
    newProductTemplates[index] = productTemplate;
    setProductTemplates(newProductTemplates);
  };

  const saveTemplate = (e) => {
    e.preventDefault();
    setSaveTemplateResult(null);
    setIsUpdateTemplateDone(false);
    const productTemplate = Object.assign({}, productTemplates[activeTab]);
    productTemplate.ProductTemplateContent =
      templateEditors[activeTab].getContents();
    updateProductTemplate({
      variables: {
        ProductTemplateInput: productTemplate,
      },
    });
  };

  const previewTemplate = () => {
    setSaveTemplateResult({
      ...saveTemplateResult,
      isPreview: true,
    });
    const productTemplate = Object.assign({}, productTemplates[activeTab]);
    productTemplate.Preview = templateEditors[activeTab].getContents();
    updateProductTemplate({
      variables: {
        ProductTemplateInput: productTemplate,
      },
    });
  };

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggleAddNewTemplateModal}>
        <ModalHeader toggle={toggleAddNewTemplateModal}>
          Add New Template
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Product Template Name</Label>
              <Input
                type="text"
                placeholder="Enter template name"
                value={newTemplate.ProductTemplateName}
                onChange={(e) =>
                  setNewTemplate({
                    ProductTemplateName: e.target.value,
                    ProductTemplateTOCLine: newTemplate.ProductTemplateTOCLine,
                    DefaultProductTemplate: newTemplate.DefaultProductTemplate,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Product "Table of Contents" Line</Label>
              <Input
                type="text"
                placeholder="Enter template TOC Line"
                value={newTemplate.ProductTemplateTOCLine}
                onChange={(e) =>
                  setNewTemplate({
                    ProductTemplateName: newTemplate.ProductTemplateName,
                    ProductTemplateTOCLine: e.target.value,
                    DefaultProductTemplate: newTemplate.DefaultProductTemplate,
                  })
                }
              />
            </FormGroup>
            {!isSystemTemplate && (
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value={newTemplate.DefaultProductTemplate}
                    onChange={(e) =>
                      setNewTemplate({
                        ProductTemplateName: newTemplate.ProductTemplateName,
                        ProductTemplateTOCLine:
                          newTemplate.ProductTemplateTOCLine,
                        DefaultProductTemplate: e.target.checked,
                      })
                    }
                  />{' '}
                  Use default new product template
                </Label>
              </FormGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={addNewTemplate}
            disabled={
              !newTemplate.ProductTemplateName ||
              !newTemplate.ProductTemplateTOCLine
            }
          >
            Add Template
          </Button>
        </ModalFooter>
      </Modal>
      {createdProductTemplateData &&
        saveTemplateResult &&
        saveTemplateResult.success &&
        !saveTemplateResult.isPreview &&
        isCreateTemplateDone === false && (
          <SweetAlert
            success
            title={<span style={{ fontSize: '24px' }}>Success</span>}
            onConfirm={(e) => {
              setSaveTemplateResult(null);
              setIsCreateTemplateDone(true);
            }}
            showConfirm={false}
            timeout={2500}
          >
            {saveTemplateResult.message}
          </SweetAlert>
        )}
      {updatedProductTemplateData &&
        saveTemplateResult &&
        saveTemplateResult.success &&
        !saveTemplateResult.isPreview &&
        isUpdateTemplateDone === false && (
          <SweetAlert
            success
            title={<span style={{ fontSize: '24px' }}>Success</span>}
            onConfirm={(e) => {
              setSaveTemplateResult(null);
              setIsUpdateTemplateDone(true);
            }}
            showConfirm={false}
            timeout={2500}
          >
            {saveTemplateResult.message}
          </SweetAlert>
        )}
      {data && data.Product && (
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col sm="12">
                  {data && (
                    <h4 className="page-title">
                      Product Templates - {data.Product.Name}
                    </h4>
                  )}
                </Col>
              </Row>
            </div>

            <Row>
              <Col lg="9">
                <Nav tabs>
                  {data &&
                    data.Product &&
                    productTemplates &&
                    productTemplates.map((template, index) => (
                      <NavItem key={index} style={{ cursor: 'pointer' }}>
                        <NavLink
                          className={classnames({
                            active: activeTab === index,
                          })}
                          onClick={() => {
                            toggle(index);
                          }}
                        >
                          {template.ProductTemplateName}
                        </NavLink>
                      </NavItem>
                    ))}
                  <Button color="secondary" onClick={toggleAddNewTemplateModal}>
                    + Add Template
                  </Button>
                </Nav>
                <TabContent activeTab={activeTab}>
                  {data &&
                    data.Product &&
                    productTemplates &&
                    productTemplates.map((template, index) => (
                      <TabPane key={index} tabId={index}>
                        <Row>
                          <Col sm="12">
                            <Card>
                              <CardBody>
                                <Label>Template Name</Label>
                                <Input
                                  type="text"
                                  placeholder="Enter template name"
                                  defaultValue={template.ProductTemplateName}
                                  onChange={(e) =>
                                    updateProductTemplateItem(
                                      e.target.value,
                                      'ProductTemplateName',
                                      index
                                    )
                                  }
                                  className={'mb-4'}
                                />
                                <Label>Table of Contents Line</Label>
                                <Input
                                  type="text"
                                  placeholder="Enter Table of Contents"
                                  defaultValue={template.ProductTemplateTOCLine}
                                  onChange={(e) =>
                                    updateProductTemplateItem(
                                      e.target.value,
                                      'ProductTemplateTOCLine',
                                      index
                                    )
                                  }
                                  className={'mb-4'}
                                />
                                <textarea
                                  id={`template-${index}`}
                                  style={{ width: '100%' }}
                                ></textarea>
                                <Button
                                  color="primary"
                                  className="mt-3"
                                  disabled={
                                    !template.ProductTemplateName ||
                                    !template.ProductTemplateTOCLine ||
                                    !template.ProductTemplateContent
                                  }
                                  onClick={saveTemplate}
                                >
                                  Save Template
                                </Button>
                                <Button
                                  color="info"
                                  className="ml-2 mt-3"
                                  onClick={() => previewTemplate(template)}
                                >
                                  Preview Template
                                </Button>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                    ))}
                </TabContent>
                {(productTemplates === null ||
                  productTemplates.length === 0) && (
                  <div
                    style={{
                      background: '#fff',
                      height: '100%',
                      minHeight: '500px',
                      maxHeight: '700px',
                      padding: '20px',
                    }}
                  >
                    No product templates for {data.Product.Name}
                  </div>
                )}
              </Col>
              <Col lg={3}>
                <Card>
                  <CardHeader>Proposal Variables</CardHeader>
                  <CardBody
                    style={{
                      height: '100%',
                      minHeight: '500px',
                      maxHeight: '690px',
                      overflowY: 'scroll',
                    }}
                  >
                    <div>
                      {quoteTableVariables.map((table, index) => (
                        <div key={`table-1-${index}`}>
                          <ButtonGroup
                            key={table.id + '1'}
                            className="mb-2 d-flex btn-grp-variables"
                          >
                            <Button
                              title={`quote1_${table.id}_table - Quote 1 ${table.name}`}
                              color="success"
                              disabled={!editorClicked}
                              onClick={() => {
                                templateEditors[activeTab].insertHTML(
                                  `%quote1_${table.id}_table%`
                                );
                              }}
                            >
                              +
                            </Button>
                            <Button
                              title={`quote1_${table.id}_table - Quote 1 ${table.name}`}
                              color="dark"
                              className="d-flex px-2 w-100 text-left"
                            >{`quote1_${table.id}_table`}</Button>
                            <Button
                              title={`quote1_${table.id}_table - Quote 1 ${table.name}`}
                              color="light"
                              className="d-flex px-2 w-100 text-left"
                            >
                              Quote 1 {table.name}
                            </Button>
                          </ButtonGroup>
                        </div>
                      ))}
                      {quoteTableVariables.map((table, index) => (
                        <div key={`table-2-${index}`}>
                          <ButtonGroup
                            key={table.id + '2'}
                            className="mb-2 d-flex btn-grp-variables"
                          >
                            <Button
                              title={`quote2_${table.id}_table - Quote 2 ${table.name}`}
                              color="success"
                              disabled={!editorClicked}
                              onClick={() => {
                                templateEditors[activeTab].insertHTML(
                                  `%quote2_${table.id}_table%`
                                );
                              }}
                            >
                              +
                            </Button>
                            <Button
                              title={`quote2_${table.id}_table - Quote 2 ${table.name}`}
                              color="dark"
                              className="d-flex px-2 w-100 text-left"
                            >{`quote2_${table.id}_table`}</Button>
                            <Button
                              title={`quote2_${table.id}_table - Quote 2 ${table.name}`}
                              color="light"
                              className="d-flex px-2 w-100 text-left"
                            >
                              Quote 2 {table.name}
                            </Button>
                          </ButtonGroup>
                        </div>
                      ))}
                      {quoteVariables &&
                        Object.keys(quoteVariables).map((key) => (
                          <div key={`variables-${key}`}>
                            {key.includes('logo') ? (
                              <ButtonGroup
                                key={key}
                                className="mb-2 d-flex btn-grp-variables"
                              >
                                <Button
                                  title={`${key} - ${key.replace(/_/g, ' ')}`}
                                  color="success"
                                  disabled={!editorClicked}
                                  onClick={() => {
                                    templateEditors[activeTab].insertHTML(
                                      `%${key}%`
                                    );
                                  }}
                                >
                                  +
                                </Button>
                                <Button
                                  title={`${key} - ${key.replace(/_/g, ' ')}`}
                                  color="dark"
                                  className="d-flex px-2 w-100 text-left"
                                >
                                  {key}
                                </Button>
                                <Button
                                  title={`${key} - ${key.replace(/_/g, ' ')}`}
                                  color="light"
                                  className="d-flex px-2 w-100 text-left"
                                >
                                  {key.replace(/_/g, ' ')}
                                </Button>
                              </ButtonGroup>
                            ) : (
                              <ButtonGroup
                                key={key}
                                className="mb-2 d-flex btn-grp-variables"
                              >
                                <Button
                                  title={`${key} - ${quoteVariables[key]}`}
                                  color="success"
                                  disabled={!editorClicked}
                                  onClick={() => {
                                    templateEditors[activeTab].insertHTML(
                                      `%${key}%`
                                    );
                                  }}
                                >
                                  +
                                </Button>
                                <Button
                                  title={`${key} - ${quoteVariables[key]}`}
                                  color="dark"
                                  className="d-flex px-2 w-100 text-left"
                                >
                                  {key}
                                </Button>
                                <Button
                                  title={`${key} - ${quoteVariables[key]}`}
                                  color="light"
                                  className="d-flex px-2 w-100 text-left"
                                >
                                  {quoteVariables[key]}
                                </Button>
                              </ButtonGroup>
                            )}
                          </div>
                        ))}
                      {quoteInfoVariables.map((table, index) => (
                        <div key={Math.round(Math.random() * 9999999)}>
                          <ButtonGroup
                            key={Math.round(Math.random() * 9999999)}
                            className="mb-2 d-flex btn-grp-variables"
                          >
                            <Button
                              title={table.name}
                              color="success"
                              disabled={!editorClicked}
                              onClick={() => {
                                templateEditors[activeTab].insertHTML(
                                  `%quote_${table.id}%`
                                );
                              }}
                            >
                              +
                            </Button>
                            <Button
                              title={table.name}
                              color="dark"
                              className="d-flex px-2 w-100 text-left"
                            >{`quote_${table.id}`}</Button>
                            <Button
                              title={table.name}
                              color="light"
                              className="d-flex px-2 w-100 text-left"
                            >
                              {table.name}
                            </Button>
                          </ButtonGroup>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}

      {
        // when data is loaded and bundle id is not valid show product does not exists error UI
        data && data.Product === null && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">Error loading this page</h4>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      Product with ID {pid} does not exists
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(ProductTemplates);
